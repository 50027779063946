/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';

import { JwtInterceptor } from './_advansys/interceptors/jwt-interceptor';
import { ErrorInterceptor } from './_advansys/interceptors/error.interceptor';

import { ToastService } from './_advansys/services/toast.service';
import { SnotifyModule, ToastDefaults, SnotifyService } from 'ng-snotify';
import { AppConfig } from './_advansys/services/app-config.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppContenidoService } from './_advansys/services/app-contenido.service';
import { ApplicationInsightsMonitoringService } from './_advansys/services/application-insights-monitoring.service';
import { NgSelectModule } from '@ng-select/ng-select';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = { dropSpecialCharacters: false};

export function advAppInit(appConfigService: AppConfig) {
  return () => appConfigService.load().then(() => console.log('iniciado'));
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    NgSelectModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    RecaptchaV3Module,
    SnotifyModule.forRoot(),
    NgxMaskModule.forRoot(options),
  ],
  providers: [
    AppConfig,
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: '6LffdyIhAAAAAMqnzTSp2QejhIDVByIWfKEERR0Z'
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (config: AppConfig) => AppConfig.settings.recaptchaV3SiteKey,
      deps: [AppConfig]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: advAppInit,
      multi: true,
      deps: [AppConfig]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ToastService,
    ApplicationInsightsMonitoringService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.recaptcha.siteKey,
    // },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    AppContenidoService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

