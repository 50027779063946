import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppConfig } from 'src/app/_advansys/services/app-config.service';
import { SplashScreenService } from './splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  @ViewChild('splashScreen', { static: true }) splashScreen: ElementRef;

  get logoSplashScreen(): string {
    if (AppConfig.settings.logoSplashScreen) {
      return `./assets/media/logos/${AppConfig.settings.logoSplashScreen}`;
    }

    return `./assets/media/logos/logo-header.svg`;
  }

  constructor(
    private el: ElementRef,
    private splashScreenService: SplashScreenService
  ) {}

  ngOnInit(): void {
    this.splashScreenService.init(this.splashScreen);
  }
}
