export class ConfiguracionGeneralDto {
  solicitudFueraDeHorario: string;
  fechaServidor: Date;
  mensajeControlHoraSolicitud: string;
  tamanoPermitidoPorArchivoMb: number;
  formatoArchivoPermitido: string;
  validarCedulaConModulo10: boolean;
  validarRncConModulo11: boolean;
  solicitaAsistencia: boolean;
  asistenciaTexto01: string;
  asistenciaTexto02: string;
  edadMinimaSolicitante: number;
  edadMinimaCoSolicitante: number;
  edadMinimaGarante: number;
  edadMinimaRepresentante: number;
  requiereOTPParaCompletarSolicitud: boolean;
  otpDestinoId: number;
  confirmacionCompletarSolicitud: string;
  enviarOTPCoSolicitante: boolean;
  enviarOTPGarante: boolean;
  enviarOTPRepresentante: boolean;
  esObligatorioLeerTerminos: boolean;
  terminosConfigurados: boolean;

  permiteSolicitanteConPasaporte: boolean;
  permiteSolicitanteConRnc: boolean;
  permiteRelacionadoConPasaporte: boolean;
  permiteRelacionadoFisicoConRnc: boolean;
  permiteRelacionadoJuridicoConRnc: boolean;
  requierePersonaQuePagaParaCompletarSolicitud: boolean;
  utilizaAutenticacionUsuarioSolicitudWeb: boolean;
  requiereComprobaciondeDestinoEnvioOTP: boolean;

  constructor(vm: any) {
    vm = vm || {};
    this.solicitudFueraDeHorario = vm.solicitudFueraDeHorario || '';
    this.fechaServidor = !vm.fechaServidor ? null : vm.fechaServidor;
    this.mensajeControlHoraSolicitud = vm.mensajeControlHoraSolicitud || '';
    this.tamanoPermitidoPorArchivoMb = vm.tamanoPermitidoPorArchivoMb || 0;
    this.formatoArchivoPermitido = vm.formatoArchivoPermitido || '';
    this.validarCedulaConModulo10 = vm.validarCedulaConModulo10 === undefined ? false : vm.validarCedulaConModulo10;
    this.validarRncConModulo11 = vm.validarRncConModulo11 === undefined ? false : vm.validarRncConModulo11;
    this.solicitaAsistencia = vm.solicitaAsistencia === undefined ? false : vm.solicitaAsistencia;
    this.asistenciaTexto01 = vm.asistenciaTexto01 || '';
    this.asistenciaTexto02 = vm.asistenciaTexto02 || '';
    this.edadMinimaSolicitante = vm.edadMinimaSolicitante || 0;
    this.edadMinimaCoSolicitante = vm.edadMinimaCoSolicitante || 0;
    this.edadMinimaGarante = vm.edadMinimaGarante || 0;
    this.edadMinimaRepresentante = vm.edadMinimaRepresentante || 0;
    this.requiereOTPParaCompletarSolicitud =
      vm.requiereOTPParaCompletarSolicitud === undefined ? false : vm.requiereOTPParaCompletarSolicitud;
    this.otpDestinoId = vm.otpDestinoId === undefined ? 2 : vm.otpDestinoId;
    this.confirmacionCompletarSolicitud = vm.confirmacionCompletarSolicitud || '';
    this.enviarOTPCoSolicitante = vm.enviarOTPCoSolicitante === undefined ? false : vm.enviarOTPCoSolicitante;
    this.enviarOTPGarante = vm.enviarOTPGarante === undefined ? false : vm.enviarOTPGarante;
    this.enviarOTPRepresentante = vm.enviarOTPRepresentante === undefined ? false : vm.enviarOTPRepresentante;
    this.esObligatorioLeerTerminos = vm.esObligatorioLeerTerminos || false;
    this.terminosConfigurados = vm.terminosConfigurados || false;
    this.permiteSolicitanteConPasaporte = vm.permiteSolicitanteConPasaporte === undefined ? true: vm.permiteSolicitanteConPasaporte;
    this.permiteSolicitanteConRnc = vm.permiteSolicitanteConRnc === undefined ? true: vm.permiteSolicitanteConRnc;
    this.permiteRelacionadoConPasaporte = vm.permiteRelacionadoConPasaporte === undefined ? true: vm.permiteRelacionadoConPasaporte;
    this.permiteRelacionadoFisicoConRnc = vm.permiteRelacionadoFisicoConRnc === undefined ? true: vm.permiteRelacionadoFisicoConRnc;
    this.permiteRelacionadoJuridicoConRnc = vm.permiteRelacionadoJuridicoConRnc === undefined ? true: vm.permiteRelacionadoJuridicoConRnc;
    this.requierePersonaQuePagaParaCompletarSolicitud = vm.requierePersonaQuePagaParaCompletarSolicitud === undefined
      ? false
      : vm.requierePersonaQuePagaParaCompletarSolicitud;

    this.requiereComprobaciondeDestinoEnvioOTP = vm.requiereComprobaciondeDestinoEnvioOTP === undefined
      ? false
      : vm.requiereComprobaciondeDestinoEnvioOTP;

    this.utilizaAutenticacionUsuarioSolicitudWeb = vm.utilizaAutenticacionUsuarioSolicitudWeb === undefined
      ? false
      : vm.utilizaAutenticacionUsuarioSolicitudWeb;
  }
}
