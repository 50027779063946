/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfiguracionGeneralDto } from '../models/configuracion-general.model';
import { AppConfig } from './app-config.service';

@Injectable()
export class AppContenidoService {
  public reCaptchaDatos = {
    usaReCaptchaV2: false,
    siteKeyV2: '',
    usaReCaptchaV3: false,
    siteKeyV3: '',
  };

  public configuracion: ConfiguracionGeneralDto;

  private API_URL = '';

  constructor(private http: HttpClient) {
    this.http = http;
    this.API_URL = `${AppConfig.settings.api}/api/solicitud-prestamos/user/solicitud-web-settings-controller`;
  }

  getDatosReCaptcha(): Observable<any> {
    const url = `${this.API_URL}/datos-recaptcha`;

    return this.http.get<any>(url)
      .pipe(
        map(response => {
          this.reCaptchaDatos.usaReCaptchaV2 = response.usaReCaptchaV2;
          this.reCaptchaDatos.siteKeyV2 = response.siteKeyV2;
          this.reCaptchaDatos.usaReCaptchaV3 = response.usaReCaptchaV3;
          this.reCaptchaDatos.siteKeyV3 = response.siteKeyV3;

          if (this.reCaptchaDatos.usaReCaptchaV3 === true) {
            this.reCaptchaDatos.usaReCaptchaV2 = false;
            // this.reCaptchaDatos.siteKeyV2 = '';
          }

          return response;
        }),
        catchError(err => of(null)),
      );
  }

  getDatosConfiguracionGeneral(): Observable<any> {
    const url = `${this.API_URL}/configuracion-general`;

    return this.http.get<any>(url)
      .pipe(
        map(response => {
          this.configuracion = new ConfiguracionGeneralDto(response);
        }),
        catchError(err => of(null)),
      );
  }
}
