import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApplicationInsightsMonitoringService } from './_advansys/services/application-insights-monitoring.service';
import { NgSelectConfig } from '@ng-select/ng-select';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private datepickerConfig: NgbDatepickerConfig,
    private recaptchaV3Service: ReCaptchaV3Service,
    private selectConfig: NgSelectConfig,
    private applicationInsightsMonitoringService: ApplicationInsightsMonitoringService
  ) {

    // INICIALIZO CONFIGURACION DE NG SELECT
    this.selectConfig.notFoundText = 'No encontrado';
    this.selectConfig.appendTo = 'body';
    // set the bindValue to global selectConfig when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.selectConfig.bindValue = 'id';
    this.selectConfig.loadingText = 'Leyendo...';
    // FIN DE INICIALIZO CONFIGURACION DE NG SELECT

    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );

    this.applicationInsightsMonitoringService.inicio();
  }

  ngOnInit() {
    this.configurarDate();

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  private configurarDate(): void {
    // customize default values of datepickers used by this component tree
    this.datepickerConfig.minDate = { year: 1900, month: 1, day: 1 };
    this.datepickerConfig.maxDate = { year: 2099, month: 12, day: 31 };

    // days that don't belong to current month are not visible and the space should be collapsed
    this.datepickerConfig.outsideDays = 'collapsed';
  }
}
