import { Injectable } from '@angular/core';

@Injectable()
export class StringHelper {

  public static advReplace(texto: string, valor: string, nuevoValor: string) {
    while (texto.indexOf(valor) >= 0) {
      texto = texto.replace(valor, nuevoValor);
    }

    return texto;
  }

  public static obtenerValorNumerico(texto: string): number {
    // TODO: se debe buscar el simbolo correspondiente dependiendo de la configuracion para ser reemplazado
    // let simbolo: string;
    // simbolo = getLocaleNumberSymbol('en-US',  NumberSymbol.Decimal);
    // console.log(simbolo);

    if (texto === undefined || texto === '') {
      return 0;
    }

    const valor = texto.replace(new RegExp(',', 'g'), '');

    return parseFloat(valor);
  }

  /** @description se utiliza retornar un valor string en caso de ser nullo o indefinido retorna en blanco
   ** @alias fStr
   */
  public static obtenerValorString(texto: any): string {
    if (texto === undefined || texto === null) {
      return '';
    }

    return `${texto}`;
  }

  public static textoValido(texto: any): boolean {
    if (texto === undefined || texto === null || texto === '') {
      return false;
    }

    return true;
  }

  public static correoEsValido(correo: string): boolean {
    // eslint-disable-next-line max-len
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(correo).toLowerCase());
  }


   /** @description se utiliza para retornar un valor string truncado dependiendo de la cantidad de caracteres deseada.
    ** @alias fTrunc
    */
  public static truncarTexto(texto: string , cantidad: number) {
    if (texto.length > cantidad) {
      return texto.substring(0, cantidad) + '...';

    } else {
      return texto;
    }
  }

  public static sanearStringParaUrl(texto: string): string {
    texto = texto
        .replace(' ', '%20')
        .replace('!', '%21')
        .replace('\'', '%22')
        .replace('#', '%23')
        .replace('$', '%24')
        .replace('%', '%25')
        .replace('&', '%26')
        .replace('\'', '%27')
        .replace('(', '%28')
        .replace(')', '%29')
        .replace('*', '%2A')
        .replace('+', '%2B')
        .replace(',', '%2C')
        .replace('-', '%2D')
        .replace('.', '%2E')
        .replace('/', '%2F')

        .replace(':', '%3A')
        .replace(';', '%3B')
        .replace('<', '%3C')
        .replace('=', '%3D')
        .replace('>', '%3E')
        .replace('?', '%3F')

        .replace('@', '%40')
        .replace('[', '%5B')
        .replace('\\', '%5C')
        .replace(']', '%5D')
        .replace('^', '%5E')
        .replace('_', '%5F')

        .replace('`', '%60')

        .replace('{', '%7B')
        .replace('|', '%7C')
        .replace('}', '%7D')
        .replace('~', '%7E')

        .replace('ñ', '%F1')
        .replace('Ñ', '%D1')
        ;

    return texto;
  }
}
